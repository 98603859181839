import * as React from "react";
import { FunctionComponent } from "react";
import { Heading, Page, PageContent, PageSection } from "../components";
import { graphql, useStaticQuery } from "gatsby";

import "./Team.css";

const ContactPage: FunctionComponent = (): JSX.Element => {
  const data = useStaticQuery(graphql`
    query {
      featuredImage: contentfulAsset(
        title: { eq: "Our Team > Featured Image" }
      ) {
        file {
          url
        }
      }
      allContentfulCollection(
        filter: { name: { eq: "Team Member Collection" } }
      ) {
        edges {
          node {
            name
            entries {
              ... on ContentfulStaffMember {
                full_name
                accreditations
                job_title
                profile_image {
                  fluid(maxWidth: 300) {
                    src
                  }
                }
                summary {
                  childContentfulRichText {
                    html
                  }
                }
              }
            }
          }
        }
      }
    }
  `);
  const teamMembers = data.allContentfulCollection.edges[0].node.entries;

  const { featuredImage } = data;

  return (
    <Page title="Our Team" layout="default">
      <PageSection>
        <PageContent className="our-team__hero">
          {featuredImage && featuredImage.file && (
            <div className="featured-image">
              <img src={featuredImage.file.url} />
            </div>
          )}
          <div>
            <Heading level={1}>
              {`An unsurpassed team of professionals, experienced in all 
                phases of Property Management`}
            </Heading>
            <p>
              {`Hawaiiana's philosophy is one of proactivity. We provide
                leadership, information and support to boards of directors so
                they can run their associations effectively and provide the
                highest quality experience for owners and residents. We provide
                accurate and timely financial reports allowing boards to track
                association finances easily, and stay on course with their
                budget and plans for the long term.`}
            </p>
          </div>
        </PageContent>
      </PageSection>
      <div className="flex-wrapper">
        <div className="tile--two-thirds">
          <Heading
            level={2}
            style={{ marginBottom: "40px", textAlign: "center" }}
          >
            Our Management Team
          </Heading>
          {teamMembers.map((teamMember, teamMemberIndex) => {
            const profileImage =
              teamMember.profile_image &&
              teamMember.profile_image.fluid &&
              teamMember.profile_image.fluid.src;
            const accreditations =
              teamMember.accreditations !== null &&
              teamMember.accreditations.map((accreditation, index) => {
                const appendedPunctuation =
                  index === teamMember.accreditations.length - 1 ? "" : ", ";
                return `${accreditation}${appendedPunctuation}`;
              });
            const fullNameAndAccreditations = `${teamMember.full_name}${
              accreditations ? `, ${accreditations}` : ""
            }`;
            const jobTitle = teamMember.job_title;
            return (
              <div
                className="team-member"
                key={`team-member-${teamMemberIndex}`}
              >
                <div className="team-member__profile">
                  <img
                    className="team-member__image"
                    src={profileImage}
                    width="190"
                  />
                  <p>{fullNameAndAccreditations}</p>
                  <p>{jobTitle}</p>
                </div>
                <div className="team-member__description">
                  <p>{fullNameAndAccreditations.toLowerCase()}</p>
                  <p>{jobTitle}</p>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: teamMember.summary.childContentfulRichText.html
                    }}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </Page>
  );
};

export default ContactPage;
